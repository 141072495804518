import React from "react"
import {useStaticQuery,Link,graphql} from "gatsby"
import Image from "gatsby-image"

const Logo = () => {

  const data = useStaticQuery(graphql`
              query {
                  file(relativePath: { eq: "LOGO/logo+name+white/3.png" }) {
                      childImageSharp {
                          # Specify a fixed image and fragment.
                          # The default width is 400 pixels
                          fluid(maxWidth: 400) {
                              ...GatsbyImageSharpFluid
                          }
                      }
                  }
              }
    `
  )

  return(
    <Link to={"/"} title={"Bestforsikring.no"}><Image fluid={data.file.childImageSharp.fluid} alt={"Logo"} title={"Til forsiden"}/></Link>
  )
}

export default Logo