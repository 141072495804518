import { graphql, Link, StaticQuery } from "gatsby"
import React from "react"

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    /*const s1 = document.createElement('script');
    s1.type = 'text/javascript';
    s1.async = true;
    s1.src = 'https://bestforsikring.no/mt-includes/js/website.assets.min.js?_build=1570116773';

    const s2 = document.createElement('script');
    s2.type = 'text/javascript';
    s2.async = true;
    s2.src = 'https://bestforsikring.no/mt-includes/js/website.min.js?_build=1573808128';

    const s3 = document.createElement('script');
    s3.type = 'text/javascript';
    s3.async = false;
    s3.innerHtml = 'var websiteConfig = websiteConfig || {};';
    s3.innerHtml += '';
    s3.innerHtml += '';
    s3.innerHtml += '';
    s3.innerHtml += '';
    s3.innerHtml += '';
    s3.innerHtml += '';
    s3.innerHtml += '';
    s3.innerHtml += '';
    s3.innerHtml += '';
    s3.innerHtml += '';
    s3.innerHtml += '';

    this.instance.appendChild(s1);
    this.instance.appendChild(s2);
    this.instance.appendChild(s3);*/
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title,
                year
              }
            },
          
            logo: file(relativePath: { eq: "logo.png" }) {
              childImageSharp{
                fixed(width:36,height:36){
                  src
                }
              }
            },
            defaqto: file(relativePath: { eq: "defaqto-stjerner.png" }) {
              childImageSharp{
                fixed(width:136,height:87){
                  src
                }
              }
            },
            bil: file(relativePath: { eq: "bilforsikring_5d191691ef49f.png" }) {
              childImageSharp{
                fixed(width:16,height:16){
                  src
                }
              }
            },
            hus: file(relativePath: { eq: "husforsikring_5d191691ef49f.png" }) {
              childImageSharp{
                fixed(width:16,height:16){
                  src
                }
              }
            },
            innbo: file(relativePath: { eq: "innboforsikring_5d191692676d3.png" }) {
              childImageSharp{
                fixed(width:16,height:16){
                  src
                }
              }
            },
            reise: file(relativePath: { eq: "reiseforsikring_5d1916926a533.png" }) {
              childImageSharp{
                fixed(width:16,height:16){
                  src
                }
              }
            },
            bat: file(relativePath: { eq: "baatforsikring_5d19169158fa0.png" }) {
              childImageSharp{
                fixed(width:16,height:16){
                  src
                }
              }
            },
            barn: file(relativePath: { eq: "barneforsikring_5d1916915a0f1.png" }) {
              childImageSharp{
                fixed(width:16,height:16){
                  src
                }
              }
            }
          }
       `}
        render={data => (
          <>
            <footer ref={el => (this.instance = el)} id="section-footer" className="footer moto-section" data-widget="section" data-container="section"
                    data-moto-sticky="{mode:'smallHeight', direction:'bottom'}">
              <div
                className="moto-widget moto-widget-row row-fixed moto-bg-color3_3 moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
                data-widget="row" data-spacing="lama" style={{}} data-bg-position="left top" data-draggable-disabled>
                <div className="container-fluid">
                  <div className="row" data-container="container">
                    <div className="moto-cell col-sm-3 moto-widget moto-widget-row__column" data-container="container"
                         data-widget="row.column" style={{}} data-bg-position="left top">
                      <div
                        className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
                        data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="mobile-v"
                        data-animation>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p
                          className="moto-text_system_14">Navigasjon</p></div>
                      </div>
                      <div
                        className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
                        data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="-" data-animation
                        data-draggable-disabled>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p
                          className="moto-text_201"><a target="_self" data-action="page" data-id={9}
                                                       className="moto-link" href="/">Hjem</a></p><p
                          className="moto-text_201"><a target="_self" href="/om-oss/" data-action="page" data-id={10}
                                                       className="moto-link">Om tjenesten</a></p><p
                          className="moto-text_201"><a target="_self" href="/blogg/" data-action="blog.index"
                                                       data-id={3} className="moto-link">Blogg</a></p><p
                          className="moto-text_201"><a target="_self" href="/kontakt-oss/" data-action="page"
                                                       data-id={12} className="moto-link">Kontakt oss</a></p></div>
                      </div>
                    </div>
                    <div className="moto-cell col-sm-4 moto-widget moto-widget-row__column" data-container="container"
                         data-widget="row.column" style={{}} data-bg-position="left top">
                      <div
                        className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
                        data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="mobile-v"
                        data-animation>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p
                          className="moto-text_system_14">Sammenligning</p></div>
                      </div>
                      <div
                        className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                        data-grid-type="sm" data-widget="row" data-spacing="aaaa" style={{}}
                        data-bg-position="left top">
                        <div className="container-fluid">
                          <div className="row" data-container="container">
                            <div
                              className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                              style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa"
                              data-bg-position="left top">
                              <div
                                className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v"
                                data-animation data-draggable-disabled>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p
                                  className="moto-text_normal"><span
                                  className="moto-content-image-plugin-wrapper moto-spacing-top-small moto-spacing-right-small moto-spacing-bottom-zero moto-spacing-left-zero"><span
                                  className="moto-content-image-container"><img className="moto-content-image"
                                                                                data-path="2019/06/bilforsikring_5d191691ef49f.png"
                                                                                data-id={224}
                                                                                alt="Sammenlign vilkår på bilforsikring"
                                                                                title="Sammenlign vilkår på bilforsikring"
                                                                                width={16} height={16}
                                                                                src={data.bil.childImageSharp.fixed.src}/></span></span><Link
                                  target="_self" to={"/bil"} data-action="page" data-id={23}
                                  className="moto-link">Bilforsikring</Link></p></div>
                              </div>
                              <div
                                className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v"
                                data-animation>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p
                                  className="moto-text_normal"><span
                                  className="moto-content-image-plugin-wrapper moto-spacing-top-small moto-spacing-right-small moto-spacing-bottom-zero moto-spacing-left-zero"><span
                                  className="moto-content-image-container"><img className="moto-content-image"
                                                                                data-path="2019/06/husforsikring_5d191691ef49f.png"
                                                                                data-id={223}
                                                                                alt="Sammenlign vilkår på husforsikring"
                                                                                title="Sammenlign vilkår på husforsikring"
                                                                                width={16} height={16}
                                                                                src={data.hus.childImageSharp.fixed.src}/></span></span><Link
                                  target="_self" to={"/hus"} data-action="page" data-id={23}
                                  className="moto-link">Husforsikring</Link>
                                </p></div>
                              </div>
                              <div
                                className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                data-grid-type="sm" data-widget="row" data-spacing="aaaa" style={{}}
                                data-bg-position="left top">
                                <div className="container-fluid">
                                  <div className="row" data-container="container">
                                    <div
                                      className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                      style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa"
                                      data-bg-position="left top">
                                      <div
                                        className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                        data-widget="text" data-preset="default" data-spacing="aaaa"
                                        data-visible-on="mobile-v" data-animation>
                                        <div className="moto-widget-text-content moto-widget-text-editable"><p
                                          className="moto-text_normal"><span
                                          className="moto-content-image-plugin-wrapper moto-spacing-top-small moto-spacing-right-small moto-spacing-bottom-zero moto-spacing-left-zero"><span
                                          className="moto-content-image-container"><img className="moto-content-image"
                                                                                        data-path="2019/06/reiseforsikring_5d1916926a533.png"
                                                                                        data-id={226}
                                                                                        alt="Sammenlign vilkår på reiseforsikring"
                                                                                        title="Sammenlign vilkår på reiseforsikring"
                                                                                        width={16} height={16}
                                                                                        src={data.reise.childImageSharp.fixed.src}/></span></span><Link
                                          target="_self" to={"/reise"} data-action="page" data-id={23}
                                          className="moto-link">Reiseforsikring</Link>
                                        </p></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                        data-grid-type="sm" data-widget="row" data-spacing="aaaa" style={{}}
                        data-bg-position="left top">
                        <div className="container-fluid">
                          <div className="row" data-container="container">
                            <div
                              className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                              style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa"
                              data-bg-position="left top">
                              <div
                                className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v"
                                data-animation>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p
                                  className="moto-text_normal"><span
                                  className="moto-content-image-plugin-wrapper moto-spacing-top-small moto-spacing-right-small moto-spacing-bottom-zero moto-spacing-left-zero"><span
                                  className="moto-content-image-container"><img className="moto-content-image"
                                                                                data-path="2019/06/innboforsikring_5d191692676d3.png"
                                                                                data-id={225}
                                                                                alt="Sammenlign vilkår på innboforsikring"
                                                                                title="Sammenlign vilkår på innboforsikring"
                                                                                width={16} height={16}
                                                                                src={data.innbo.childImageSharp.fixed.src}/></span></span><Link
                                  target="_self" to={"/innbo"} data-action="page" data-id={23}
                                  className="moto-link">Innboforsikring</Link>
                                </p></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                        data-grid-type="sm" data-widget="row" data-spacing="aaaa" style={{}}
                        data-bg-position="left top">
                        <div className="container-fluid">
                          <div className="row" data-container="container">
                            <div
                              className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                              style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa"
                              data-bg-position="left top">
                              <div
                                className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v"
                                data-animation>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p
                                  className="moto-text_normal"><span
                                  className="moto-content-image-plugin-wrapper moto-spacing-top-small moto-spacing-right-small moto-spacing-bottom-zero moto-spacing-left-zero"><span
                                  className="moto-content-image-container"><img className="moto-content-image"
                                                                                data-path="2019/06/barneforsikring_5d1916915a0f1.png"
                                                                                data-id={222}
                                                                                alt="Sammenlign vilkår på barneforsikring"
                                                                                title="Sammenlign vilkår på barneforsikring"
                                                                                width={16} height={16}
                                                                                src={data.barn.childImageSharp.fixed.src}/></span></span><Link
                                  target="_self" to={"/barn"} data-action="page" data-id={23}
                                  className="moto-link">Barneforsikring</Link>
                                </p></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                        data-grid-type="sm" data-widget="row" data-spacing="aaaa" style={{}}
                        data-bg-position="left top">
                        <div className="container-fluid">
                          <div className="row" data-container="container">
                            <div
                              className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                              style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa"
                              data-bg-position="left top">
                              <div
                                className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
                                data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="mobile-v"
                                data-animation>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p
                                  className="moto-text_normal"><span
                                  className="moto-content-image-plugin-wrapper moto-spacing-top-small moto-spacing-right-small moto-spacing-bottom-zero moto-spacing-left-zero"><span
                                  className="moto-content-image-container"><img className="moto-content-image"
                                                                                data-path="2019/06/baatforsikring_5d19169158fa0.png"
                                                                                data-id={221}
                                                                                alt="Sammenlign vilkår på båtforsikring"
                                                                                title="Sammenlign vilkår på båtforsikring"
                                                                                width={16} height={16}
                                                                                src={data.bat.childImageSharp.fixed.src}/></span></span><Link
                                  target="_self" to={"/bat"} data-action="page" data-id={23}
                                  className="moto-link">Båtforsikring</Link>
                                </p></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="moto-cell col-sm-2 moto-widget moto-widget-row__column" data-container="container"
                         data-widget="row.column" style={{visibility:'hidden'}} data-bg-position="left top">
                      <div
                        className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
                        data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="mobile-v"
                        data-animation>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p
                          className="moto-text_system_14">Følg oss</p></div>
                      </div>
                      <div
                        className="moto-widget moto-widget-row row-gutter-0 moto-justify-content_center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                        data-widget="row" data-spacing="aaaa" data-grid-type="xs" style={{}}
                        data-bg-position="left top">
                        <div className="container-fluid">
                          <div className="row" data-container="container">
                            <div className="moto-cell col-xs-2 moto-widget moto-widget-row__column"
                                 data-container="container" data-widget="row.column" style={{}}
                                 data-bg-position="left top">
                              <div
                                className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                data-widget="text" data-preset="default" data-spacing="aaaa" data-animation>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p
                                  className="moto-text_201" style={{ textAlign: 'center' }}><span
                                  className="moto-color1_3"><span className="fa"></span></span></p></div>
                              </div>
                            </div>
                            <div className="moto-cell col-xs-10 moto-widget moto-widget-row__column"
                                 data-container="container" data-widget="row.column" style={{}}
                                 data-bg-position="left top">
                              <div
                                className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-small"
                                data-widget="text" data-preset="default" data-spacing="aaas" data-visible-on="-"
                                data-animation>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p
                                  className="moto-text_201"><a target="_self" data-action="url" className="moto-link"
                                                               href="#">Facebook</a></p></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="moto-widget moto-widget-row row-gutter-0 moto-justify-content_center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                        data-widget="row" data-spacing="aaaa" data-grid-type="xs" style={{}}
                        data-bg-position="left top">
                        <div className="container-fluid">
                          <div className="row" data-container="container">
                            <div className="moto-cell col-xs-2 moto-widget moto-widget-row__column"
                                 data-container="container" data-widget="row.column" style={{}}
                                 data-bg-position="left top">
                              <div
                                className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                data-widget="text" data-preset="default" data-spacing="aaaa" data-animation>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p
                                  className="moto-text_201" style={{ textAlign: 'center' }}><span
                                  className="moto-color1_3"><span className="fa"></span></span></p></div>
                              </div>
                            </div>
                            <div className="moto-cell col-xs-10 moto-widget moto-widget-row__column"
                                 data-container="container" data-widget="row.column" style={{}}
                                 data-bg-position="left top">
                              <div
                                className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-small"
                                data-widget="text" data-preset="default" data-spacing="aaas" data-animation>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p
                                  className="moto-text_201"><a href="#" target="_self" data-action="url"
                                                               className="moto-link">Twitter</a></p></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                        data-grid-type="sm" data-widget="row" data-spacing="aaaa" style={{}}
                        data-bg-position="left top">
                        <div className="container-fluid">
                          <div className="row" data-container="container">
                            <div
                              className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                              style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa"
                              data-bg-position="left top">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="moto-cell col-sm-3 moto-widget moto-widget-row__column" data-container="container"
                         data-widget="row.column" style={{}} data-bg-position="left top">
                      <div
                        className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
                        data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="mobile-v"
                        data-animation>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p
                          className="moto-text_system_14">Om oss</p></div>
                      </div>
                      <div
                        className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                        data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v"
                        data-animation data-draggable-disabled>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p
                          className="moto-text_system_11">BestForsikring.no er en tjeneste som rangerer norske
                          forsikrings-produkter&nbsp;etter kvalitet på vilkår.</p><p className="moto-text_system_11">
                          <br/></p><p className="moto-text_system_11">Alle data i sammenligningene våre er lisensiert
                          fra og leveres av <a target="_blank" data-action="url" className="moto-link"
                                               href="http://defaqto.no">Defaqto Norway</a> &nbsp;- et anerkjent og
                          uavhengig ratingselskap som henter inn og monitorerer vilkårsdata fra norske
                          forsikringsselskaper.</p><p className="moto-text_system_11"><br/></p><p
                          className="moto-text_system_11">Alt innhold på BestForsikring.no er opphavsrettslig beskyttet.
                          Kopiering og ulisensiert bruk&nbsp;av innhold, inklusiv data og grafikk&nbsp;fra Defaqto
                          Norway, vil bli strafferettslig forfulgt.</p><p className="moto-text_system_11"><br/></p><p
                          className="moto-text_system_11"><br/></p></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="moto-widget moto-widget-row row-fixed moto-bg-color2_3 moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto"
                data-widget="row" data-spacing="sasa" style={{}} data-bg-position="left top">
                <div className="container-fluid">
                  <div className="row" data-container="container">
                    <div className="moto-cell col-sm-12 moto-widget moto-widget-row__column" data-container="container"
                         data-widget="row.column" style={{}} data-bg-position="left top">
                      <div
                        className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                        data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v"
                        data-animation>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p
                          className="moto-text_202"><span
                          className="moto-content-image-plugin-wrapper moto-spacing-top-zero moto-spacing-right-small moto-spacing-bottom-zero moto-spacing-left-zero"><span
                          className="moto-content-image-container"><img className="moto-content-image"
                                                                        data-path="2016/12/mt-0756_footer_logo.png"
                                                                        data-id={167} alt="" width={22} height={22}
                                                                        src="/mt-content/uploads/2016/12/mt-0756_footer_logo.png"/></span></span><strong><span
                          className="moto-color5_5">BestForsikring.no&nbsp;<span
                          style={{ fontSize: '12px' }}>© {data.site.siteMetadata.year} &nbsp; | &nbsp; <a data-action="page" data-id={13}
                                                                        className="moto-link"
                                                                        href={"/personvern/"}>Personvern</a></span></span></strong>
                        </p></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
            <div data-moto-back-to-top-button className="moto-back-to-top-button">
              <a ng-click="toTop($event)" className="moto-back-to-top-button-link">
                <span className="moto-back-to-top-button-icon fa"/>
              </a>
            </div>
          </>
        )}
      />
    )
  }
}
