import { graphql, Link, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
//import Logo from "../svgs/logo.svg"
//import Logo from "../images/LOGO/logo+white/Logo@4x.png"
import Logo from "./logo.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars} from "@fortawesome/free-solid-svg-icons"
import Logotype from "./logotype"


class HeaderMenu extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    }
  }

  resized = () => {
    this.setState({expanded: false});
  };
  componentDidMount() {
    window.addEventListener('resize', this.resized);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resized);
  }

  flipMobileMenu = (event) => {
    this.setState({expanded:!this.state.expanded});
    event.preventDefault();
  }

  render() {
    return (
      <>
        <div className="moto-widget moto-widget-row moto-justify-content_center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-grid-type="xs" data-widget="row" data-spacing="aaaa" data-bg-position="left top">
          <div className="container-fluid">
            <div style={{display:'flex',alignItems:'flex-start'}} className="row" data-container="container">
              <div className="moto-widget moto-widget-row__column moto-cell col-xs-4 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                <div style={{paddingTop:'0'}} className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa" data-visible-on="mobile-v" data-animation="true">
                  <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}} className="moto-widget-text-content moto-widget-text-editable">
                    <p style={{flex:1,display:'flex',margin:0,padding:0}} className="moto-text_system_1">
                      <span style={{width:'300px',marginTop:'-30px'}} className="moto-content-image-plugin-wrapper moto-spacing-top-zero moto-spacing-right-small moto-spacing-bottom-zero moto-spacing-left-zero">
                        <span style={{width:'inherit',height:'inherit'}} className="moto-content-image-container">
                          <Link alt={"Logo"} title="Til forsiden" style={{width:'inherit',height:'inherit'}} to="/">
                            <Logo/>
                            {/*<div className="moto-content-image" width={36} height={36} data-id={145} className="moto-content-image" alt={"Logo"} src={this.props.logoImage.childImageSharp.fixed.src} dangerouslySetInnerHTML={{ __html: this.props.logoImage?.childInlineSvg?.content }}/>*/}
                          </Link>
                        </span>
                      </span>
                      {/*<span style={{minWidth:'200px'}}>
                        <Logotype/>
                      </span>*/}
                    </p>
                    <p style={{flex:1,display:'block'}} className="moto-text_system_2">
                      <span style={{fontSize: '13px',paddingLeft:'15px',userSelect:'none',color:'white'}}>Uavhengige ekspertvurderinger av forsikringer</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="moto-widget moto-widget-row__column moto-cell col-xs-8 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                <div data-widget-id="wid__menu__5c975facc0eb4" className="moto-widget moto-widget-menu moto-preset-default moto-align-right moto-align-right_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-preset="default" data-widget="menu">

                  <a style={{fontSize:'30px',color:'white',background:'none'}} href="#" onClick={this.flipMobileMenu} className="button button-toggle moto-widget-menu-toggle-btn">
                    {/*<i className="moto-widget-menu-toggle-btn-icon fa fa-bars" />*/}
                    <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
                  </a>

                  <ul className={`moto-widget-menu-list moto-widget-menu-list_horizontal${this.state.expanded && ' activeMenu nav'}`}>
                    <li className="nav-item moto-widget-menu-item"><Link to="/bil/" data-action="page" className={`moto-widget-menu-link moto-widget-menu-link-level-1 moto-link${this.props.siteSection === 'bil' && ' moto-widget-menu-link-active'}`}>BIL</Link>
                    </li>
                    <li className="nav-item moto-widget-menu-item"><Link to="/hus/" data-action="page" className={`moto-widget-menu-link moto-widget-menu-link-level-1 moto-link${this.props.siteSection === 'hus' && ' moto-widget-menu-link-active'}`}>HUS</Link>
                    </li>
                    <li className="nav-item moto-widget-menu-item"><Link to="/reise/" data-action="page" className={`moto-widget-menu-link moto-widget-menu-link-level-1 moto-link${this.props.siteSection === 'reise' && ' moto-widget-menu-link-active'}`}>REISE</Link>
                    </li>
                    <li className="nav-item moto-widget-menu-item"><Link to="/innbo/" data-action="page" className={`moto-widget-menu-link moto-widget-menu-link-level-1 moto-link${this.props.siteSection === 'innbo' && ' moto-widget-menu-link-active'}`}>INNBO</Link>
                    </li>
                    <li className="nav-item moto-widget-menu-item"><Link to="/barn/" data-action="page" className={`moto-widget-menu-link moto-widget-menu-link-level-1 moto-link${this.props.siteSection === 'barn' && ' moto-widget-menu-link-active'}`}>BARN</Link>
                    </li>
                    <li className="nav-item moto-widget-menu-item"><Link to="/bat/" data-action="page" className={`moto-widget-menu-link moto-widget-menu-link-level-1 moto-link${this.props.siteSection === 'bat' && ' moto-widget-menu-link-active'}`}>BÅT</Link>
                    </li>
                    <li className="nav-item moto-widget-menu-item moto-widget-menu-item-has-submenu">
                      <a href="/om-oss/" data-action="page" className="moto-widget-menu-link moto-widget-menu-link-level-1 moto-widget-menu-link-submenu moto-link">OM TJENESTEN<span className="fa moto-widget-menu-link-arrow" /></a>
                      <ul className="moto-widget-menu-sublist"><li className="moto-widget-menu-item"><a href="/om-oss/" data-action="page" className="moto-widget-menu-link moto-widget-menu-link-level-2 moto-link">Om oss</a></li><li className="moto-widget-menu-item"><a href="/blogg/" data-action="blog.index" className="moto-widget-menu-link moto-widget-menu-link-level-2 moto-link">Blogg</a></li><li className="moto-widget-menu-item"><a href="/faq/" data-action="page" className="moto-widget-menu-link moto-widget-menu-link-level-2 moto-link">Ofte stilte spørsmål</a></li><li className="moto-widget-menu-item"><a href="/kontakt-oss/" data-action="page" className="moto-widget-menu-link moto-widget-menu-link-level-2 moto-link">Kontakt oss</a></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default HeaderMenu