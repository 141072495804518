import { graphql, Link, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import HeaderMenu from "./headerMenu"

class HeaderSmall extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    }
  }

  flipMobileMenu(){
    this.setState({expanded:!this.state.expanded});
  }

  render() {
    return (
    <StaticQuery
    query={graphql`
        query {
          logo: file(relativePath: { eq: "logo.png" }) {
            childImageSharp{
              fixed(width:36,height:36){
                src
              }
            }
          },
          defaqto: file(relativePath: { eq: "defaqto-stjerner.png" }) {
            childImageSharp{
              fixed(width:136,height:87){
                src
              }
            }
          },
          car: file(relativePath: { eq: "mt-0756_header_img01.png" }) {
            childImageSharp{
              fixed(width:971,height:740){
                src
              }
            }
          }
        }
     `}
    render={data => (
      <>
        <header id="section-header" className="header moto-section" data-widget="section" data-container="section">
          <div className="moto-widget moto-widget-row row-fixed moto-bg-color1_3 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="aasa" data-bg-position="left top" data-draggable-disabled="true">
            <div className="container-fluid">
              <div className="row" data-container="container">
                <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">

                  <div data-widget-id="wid_1553424523_8w11g7e5f" className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto " data-widget="spacer" data-preset="default" data-spacing="sasa" data-visible-on="mobile-v">
                    <div className="moto-widget-spacer-block" style={{height: '5px'}} />
                  </div>

                  <HeaderMenu logoImage={data.logo} siteSection={this.props.siteSection}/>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    )}
    />
    )
  }
}

HeaderSmall.propTypes = {
  siteSection: PropTypes.string,
}

HeaderSmall.defaultProps = {
  siteSection: ``,
}

export default HeaderSmall
